
import { Component, Vue } from 'vue-property-decorator';
import { AuthService } from '@/services';
import OnboardLayout from '@/components/OnboardLayout.vue';
import Title from '@/components/common/Title.vue';

@Component({
  components: { OnboardLayout, Title }
})
export default class ForgotPassword extends Vue {
  form = {
    email: ''
  };

  async onSend() {
    const { email } = this.form;

    try {
      await AuthService.forgot({ email });
    } catch (e) {
      this.$notifyError(this.$getError(e));
      return;
    }

    const { redirectUrl = '/email-verification?email=' + encodeURIComponent(email) } = this.$route.query;

    window.location.replace(redirectUrl as string);
  }
}
